import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import {
  Container, Row, Col, Spinner, Button,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  ErrorHandler, Svg, CustomTable, CustomDropdown,
} from '../../component/common';
import {
  assignDeliveryPartner,
  order, orderComments as loadOrderComments, orderDispute,
  orders, storecomments as loadStoreComments, orderCancellationReason,
  modifications,
  grabOrder
} from '../../assets/api/axios';
import { Utils } from '../../utilities';
import '../../assets/scss/OrderManagment/OrderDetails.scss';
import WhatsappNotifications from '../../layouts/order-management/order-details/WhatsappNotifications';
import IvrDetails from '../../layouts/order-management/order-details/IvrDetails';
import TransactionMeta from '../../layouts/order-management/order-details/TransactionMeta';
import { dateString, secondToTimeFormat } from '../../utilities/Utils';
import UpdatDeliveryStatus from '../../layouts/order-management/UpdatDeliveryStatus';
import AddComments from '../../layouts/order-management/order-details/AddComments';
import ModifyOrder from '../../layouts/order-management/order-details/modify-order/ModifyOrder';
import ValueOffer from '../../layouts/order-management/order-details/ValueOffer';
import ExotelCall from '../../layouts/order-management/ExotelCall';
import ResolveDispute from '../../layouts/order-management/order-details/ResolveDispute';
import RerouteOrder from '../../layouts/order-management/order-details/RerouteOrder';
import ViewPrescription from '../../layouts/order-management/order-details/ViewPrescription';
import WhatAppTemplate from '../../component/common/WhatsAppTemplate';
import SMSTemplate from '../../component/common/SMSTemplate';
import ViewProductHistory from '../../layouts/order-management/order-details/ViewProductHistory';
import RerouteDisposition from '../../layouts/order-management/order-details/RerouteDisposition';
import ReconcileModal from '../../layouts/order-management/order-details/ReconcileModal';
import CustomAudioPlayer from '../../component/common/CustomAudioPlayer';

function getStatusIcon(status) {
  return status
    ? <span className="text-success">&#10004;</span>
    : <span className="text-danger">&#10006;</span>;
}

function getErrorLayout(retryCall) {
  return (
    <div className="align-items-center text-danger d-flex">
      Oops! Error while fetching logs.
      <Button
        variant="link"
        onClick={retryCall}
      >
        <b>
          Retry
        </b>
      </Button>
    </div>
  );
}

function getLoadingLayout() {
  return (
    <Spinner
      variant="primary"
      animation="border"
    />
  );
}

const getTableLayout = (data) => (
  <Container
    fluid
    className="mx-0 bg-white rounded h-100"
  >
    <Row>
      <Col
        xs={24}
      >
        {data.map((item) => (
          <Row
            key={item.header}
            className={`justify-content-between fs-0
            text-medium py-2 border-top flex-row`}
          >
            <Col
              xs="auto"
              className="font-weight-bold"
            >
              {item.header}
            </Col>
            <Col
              xs="auto"
            >
              {item.content}
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
  </Container>
);

class OrderDetails extends React.Component {
  constructor(props) {
    super();
    const { match, history } = props;
    const param = QueryString.parse(history.location.search);
    this.state = {
      orderId: match.params.orderId || param.orderId,
      orderDetails: null,
      loading: true,
      error: false,
      param,
      whatsappNotifications: false,
      showPrescription: false,
      transactionMeta: false,
      storeCommentsLoadReq: '',
      orderCommentsLoadReq: '',
      storeComments: null,
      orderComments: '',
      couponCode: '',
      applyingCoupon: '',
      applyCouponMsg: '',
      deliveryStatusModal: false,
      newDeliveryStatus: '',
      updatingOrderStatus: '',
      showCommentsModal: '',
      modifyOrderItemsModal: false,
      isDeliverySupport3PLPatch: false,
      isDeliverySupport3PLPatchFailed: false,
      modifiedOrderPatchStatus: '',
      showExotelCallModal: false,
      initiateCallTo: '',
      showResolveDisputeModal: false,
      disputeLogs: [],
      showRerouteOrderModal: false,
      customerPhoneNumber: '',
      storePhoneNumber: '',
      whatsappModalCustomer: false,
      whatsappModalRetailer: false,
      smsModalCustomer: false,
      smsModalRetailer: false,
      productHistory: false,
      selectedProductId: '',
      showRerouteDispositionModal: false,
      orderCancelReason: [],
      modificationComment:'',
      totalTimeEllapsed: '',
      modifyError:'Oops Something went wrong!',
      showReconcileModal: false,
      grabOrderMsg: '',
    };
    this.createdOnTimer = -1;
  }

  componentDidMount = () => {
    this.handleLoad();
    this.loadOrderCancelReason();
  }

  // eslint-disable-next-line react/sort-comp
  getStoreComments = () => {
    this.setState({
      storeCommentsLoadReq: 'loading',
    });
    const { orderDetails } = this.state;
    const date = new Date();
    const startDate = date.setDate(date.getDate() - 10);
    const endDate = new Date().setHours(23, 59, 59);
    loadStoreComments(
      'GET',
      orderDetails.store.code,
      startDate,
      endDate,
    ).then((res) => {
      this.setState({
        storeCommentsLoadReq: 'success',
        storeComments: res.data.data.comments,
      });
    })
      .catch(() => {
        this.setState({
          storeCommentsLoadReq: 'error',
        });
      });
  }

  getOrderComments = () => {
    this.setState({
      orderCommentsLoadReq: 'loading',
    });
    const { orderDetails } = this.state;
    const date = new Date();
    const startDate = date.setDate(date.getDate() - 30);
    const endDate = new Date().setHours(23, 59, 59);
    loadOrderComments(
      'GET',
      orderDetails.orderId,
      {
        startDate,
        endDate,
      },
    ).then((res) => {
      this.setState({
        orderCommentsLoadReq: 'success',
        orderComments: res.data.data.comments,
      });
    })
      .catch(() => {
        this.setState({
          orderCommentsLoadReq: 'error',
        });
      });
  }

  // handleLoad = () => {
  //   const { orderId } = this.state;
  //   orders('get', orderId)
  //     .then((res) => {
  //       this.setState({
  //         loading: false,
  //         orderDetails: { ...res.data.data },
  //       }, () => {
  //         this.getStoreComments();
  //         this.getOrderComments();
  //         if(res.data.data.store.deliveryClass !== 'NEXTDAY') this.startCreatedOnTimer();
  //       });
  //     })
  //     .catch(() => {
  //       this.setState({
  //         loading: false,
  //         error: true,
  //       });
  //     });
  //   this.getDisputeLogs();
  // }

  handleLoad = () => {
    const { orderId } = this.state;
  
    orders('get', orderId)
      .then((res) => {
        this.setState({
          loading: false,
          orderDetails: { ...res.data.data },
        }, () => {
          this.getStoreComments();
          this.getOrderComments();
  
          const deliveryClass = res.data.data?.store?.deliveryClass;
          const orderCreatedOn = res.data.data?.createdOn;
          const shopOpenTime = res.data.data?.store?.shopOpenTime;
  
          if (deliveryClass !== 'NEXTDAY') {
            this.startCreatedOnTimer();
          } else {
            this.scheduleTimerForNextDay(shopOpenTime);
          }
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });
  
    this.getDisputeLogs();
  };
  
  scheduleTimerForNextDay = (shopOpenTime) => {
    const now = new Date().getTime();
    const shopOpenDateTime = new Date(shopOpenTime).getTime() - 330 * 60 * 1000;  // shopOpenTime is in IST so subtracting 5 hours 30 minutes
    if( now > shopOpenDateTime){
      this.startCreatedOnTimer(shopOpenDateTime)
    }
  }
  
  startCreatedOnTimer = (shopOpenDateTime) => {
    const { orderDetails } = this.state;
    const createdOnTime = orderDetails.createdOn;
    const orderClosed = orderDetails.deliveryStatus === 'DELIVERED' || orderDetails.deliveryStatus === 'CANCELLED';
    const shopDeliveryClass = orderDetails.store?.deliveryClass;
    let tillTime = orderClosed ? new Date(orderDetails.orderClosedOn).getTime() : new Date().getTime();
    let timeEllapsed = (shopDeliveryClass === "NEXTDAY" && !orderClosed) ?((tillTime - new Date(shopOpenDateTime).getTime())) / 1000:((tillTime - new Date(createdOnTime).getTime())) / 1000; // in seconds
    this.setState({
      totalTimeEllapsed: timeEllapsed,
    }, () => {
      clearInterval(this.createdOnTimer);
      if (!orderClosed) {
        this.createdOnTimer = setInterval(() => {
          this.setState((state) => ({
            totalTimeEllapsed: state.totalTimeEllapsed + 1,
          }));
        }, 1000);
      }
    });
  }

  loadOrderCancelReason = (retry = 3) => {
    orderCancellationReason(
      'GET',
    ).then((res) => {
      this.setState({
        orderCancelReason: res.data.data.cancellationReasons,
      });
    }).catch(() => {
      if (retry) {
        this.loadOrderCancelReason(retry - 1);
      }
    });
  }

  getDisputeLogs = (retryCnt = 3) => {
    const { orderId } = this.state;
    orderDispute(
      'GET',
      orderId,
    ).then((res) => {
      const { disputeLogs } = this.state;
      this.setState({ disputeLogs:res.data.data });
    }).catch(() => {
      if (retryCnt) {
        this.getDisputeLogs(retryCnt - 1);
      }
    });
  }

  toggleModal = (modal) => {
    this.setState((state) => ({
      [modal]: !state[modal],
    }));
  }

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  }

  toggleDeliveryStatusConfirmationModal = (newDeliveryStatus) => {
    const { deliveryStatusModal } = this.state;
    this.setState({
      newDeliveryStatus,
      updatingOrderStatus: '',
      deliveryStatusModal: !deliveryStatusModal,
      showReconcileModal: false,
    });
  }

  patchOrder = (orderId, payload) => {
    this.setState({
      updatingOrderStatus: 'loading',
      updateOrderErrorMsg: '',
    });
    order(
      'PATCH',
      payload,
      '', {},
      orderId,
    ).then((res) => {
      this.setState({
        updatingOrderStatus: '',
        deliveryStatusModal: false,
        orderDetails: res.data.data,
      });
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong!! Please Try Again!!';
      if (err?.response?.data?.server_response) {
        errorMsg = err.response.data.server_response;
      }
      this.setState({
        updatingOrderStatus: 'error',
        deliveryStatusModal: false,
        updateOrderErrorMsg: errorMsg,
      });
    });
    this.retry = () => {
      this.patchOrder(orderId, payload);
    };
  }

  onModifyOrder = (newOrderItems, newCustomOrderItems, replaceProductIds,orderModificationComment) => {
    const { orderDetails } = this.state;
    const retailerProducts = newOrderItems.map((item) => {
        let newItem = {
          retailerProductId: item.retailerProductId === 0 ? '' : item.retailerProductId,
          quantity: item.quantity,
          sellingPrice: item.sellingPrice,
          mrp:item.mrp,
        };
        replaceProductIds.forEach((replacedItem) => {
          if (item.id === replacedItem.replaceId) {
            newItem = {
              ...newItem,
              oldRetailerProductId: item.oldRetailerProductId,
          };
          }
        });
        return newItem;
      });
    const customRetailerProducts = newCustomOrderItems.map((item) => ({
      customProductId: item.id,
      quantity: item.quantity,
      sellingPrice: item.sellingPrice,
      unitString: item.unitString,
    }));
    this.setState({ 
      modifiedOrderPatchStatus: 'loading',
      modificationComment: orderModificationComment, 
    });
    modifications(
      'POST',
      {
        retailerProducts,
        customRetailerProducts,
      },
      orderDetails.orderId,
    ).then((res) => {
      if(res.data.data.orderId){
      this.setState({
          orderDetails: res.data.data,
          modifiedOrderPatchStatus: '',
        }, () => {
          this.toggleModifyOrderItems();
        });
      }
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong!! Please Try Again!!';
      if (err?.response?.data?.errors[0]?.message) {
        errorMsg = err.response.data.errors[0].message;
      }
      this.setState({ 
        modifiedOrderPatchStatus: 'error',
        modifyError: errorMsg, 
      });
    });
  }

  patchModifyChanges = (action) => {
    const { modificationComment, orderDetails } = this.state;
    modifications(
      'PATCH',
      {
        action,
        comment: modificationComment,
      },
      orderDetails.orderId,
    ).then((res) => {
      this.setState({
        orderDetails: res.data.data,
      });
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong!! Please Try Again!!';
      if (err?.response?.data?.errors[0]?.message) {
        errorMsg = err.response.data.errors[0].message;
      }
      this.setState({ 
        modifiedOrderPatchStatus: 'error',
        modifyError: errorMsg, 
      });
    });
  }

  handleChangeDeliveryStatus = async (cancellationReason, comment, cancelReason = '') => {
    const { orderDetails, newDeliveryStatus } = this.state;
    await this.patchOrder(
      orderDetails.orderId,
      {
        action: 'PATCH_DELIVERY_STATUS',
        deliveryStatus: newDeliveryStatus,
        comment: newDeliveryStatus === 'CANCELLED' ? `${cancellationReason} - ${comment}` : '',
        orderStatusComment: comment,
        cancelReason: newDeliveryStatus === 'CANCELLED' ? `${cancelReason}` : '',
        isOrderDetails:true,
      },
    );
  }

  addComments = (showCommentsModal = '') => {
    this.setState({
      showCommentsModal,
    });
  }

  onAddComment = (onItem) => {
    this.setState({
      showCommentsModal: '',
    }, () => {
      if (onItem === 'store') { this.getStoreComments(); } else { this.getOrderComments(); }
    });
  }

  toggleModifyOrderItems = () => {
    this.setState((state) => ({
      modifyOrderItemsModal: !state.modifyOrderItemsModal,
    }));
  }

  orderDetailsForModification = () => {
    const { orderDetails } = this.state;
    return ({
      ...orderDetails,
      orderItems: orderDetails
        ? orderDetails.orderItems.map((item) => ({ ...item, modificationFactor: 'None' })) : [],
      customOrderItems: orderDetails
        ? orderDetails.customOrderItems.map((item) => ({ ...item, modificationFactor: 'None' })) : [],
    });
  }

  getAssignDeliveryPartnerDetails = () => {
    this.setState({
      isDeliverySupport3PLPatch: true,
      isDeliverySupport3PLPatchFailed: false,
    });
    const { orderDetails } = this.state;

    assignDeliveryPartner(
      'GET',
      orderDetails.orderId,
    ).then((res) => {
      this.setState({
        order: {
          ...orderDetails,
          deliveryPartnerDetails: {
            ...orderDetails.deliveryPartnerDetails,
            ...res.data,
          },
        },
        isDeliverySupport3PLPatch: false,
      });
    }).catch(() => {
      this.setState({
        isDeliverySupport3PLPatch: false,
        isDeliverySupport3PLPatchFailed: true,
      });
    });
  }

  onSuccessfulReroute = (reroutedOrderIdDetails) => {
    this.setState((state) => ({
      showRerouteOrderModal: !state.showRerouteOrderModal,
    }));
    this.handleLoad();
    window.open(`/order-details/${reroutedOrderIdDetails.orderId}`);
  }

  toggleRerouteOrder = () => {
    this.setState((state) => ({
      showRerouteOrderModal: !state.showRerouteOrderModal,
    }));
  }

  patchOM = (data) => {
    const { orderDetails } = this.state;
    this.patchOrder(
      orderDetails.orderId,
      {
        action: 'PATCH_OWNER',
        owner: data.orderManager,
        isOrderDetails:true,
      },
    );
  }

  toggleExotelCallModal = (initiateCallTo = '') => {
    this.setState((state) => ({
      showExotelCallModal: !state.showExotelCallModal,
      initiateCallTo,
    }));
  }

  toggleResolveDispute = () => {
    this.setState((state) => ({
      showResolveDisputeModal: !state.showResolveDisputeModal,
    }));
  }

  toggleReconciledModal = () => {
    this.setState((state) => ({
      showReconcileModal: !state.showReconcileModal,
    }));
  }

  getReroutedOrderStatusColor = (deliveryStatus) => {
    switch (deliveryStatus) {
      case 'PENDING':
        return 'bg-primary';
      case 'DELIVERED':
        return 'bg-green';
      case 'CANCELLED':
        return 'bg-danger';
      default:
        return 'bg-warning';
    }
  }

  markForGrab = (shouldMarkForGrab) => {
    const { orderDetails } = this.state;
    this.setState({
      grabOrderMsg: '',
    });
    grabOrder(
      'PATCH',
      {
        orderId: orderDetails.orderId,
        grabFlow: `${shouldMarkForGrab}`,
      }
    ).then((res) => {
      if (res.status === 200) {
        this.handleLoad();
      } else {
        throw new Error();
      }
    }).catch((err) => {
      let errorMsg = 'Oops Something went wrong. Retry!';
      if (err?.response?.data?.errors[0]?.message) {
        errorMsg = err.response.data.errors[0].message;
      }
      this.setState({
        grabOrderMsg: errorMsg,
      });
    });
  };

  render() {
    const {
      loading, error, orderDetails, orderId,
      whatsappNotifications, transactionMeta, showPrescription,
      storeCommentsLoadReq, orderCommentsLoadReq,
      storeComments, orderComments, deliveryStatusModal,
      newDeliveryStatus, updatingOrderStatus, updateOrderErrorMsg,
      showCommentsModal, modifyOrderItemsModal,
      isDeliverySupport3PLPatch,
      isDeliverySupport3PLPatchFailed, modifiedOrderPatchStatus,
      showExotelCallModal, initiateCallTo, showResolveDisputeModal,
      disputeLogs, showRerouteOrderModal, customerPhoneNumber, storePhoneNumber,
      whatsappModalCustomer, whatsappModalRetailer, smsModalCustomer, smsModalRetailer,
      selectedProductId, showRerouteDispositionModal, orderCancelReason, modifyError,
      totalTimeEllapsed,showReconcileModal, grabOrderMsg,
    } = this.state;

    // const audioUrlTry = 'https://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Kangaroo_MusiQue_-_The_Neverwritten_Role_Playing_Game.mp3';
    const trackTitle = 'Great song by random artist';

    // after 15 min its in red
    const orderAcceptanceTimeOver = Math.floor(totalTimeEllapsed / 60) > 15;

    const { userPermission } = this.props;

    const headers = [
      {
        key: 'id',
        displayText: 'Id',
        renderer: (data) => (
          <>
            <div>
              {data.id}
            </div>
            {data.isFreeSample &&(            
              <div
                className="product-free-sample"
              >
                <span className='text-white font-weight-bold fs-001'>
                    Free Sample
                </span>
              </div>
            )}  
            {data.isFlashSaleProduct &&(    
              <div
                className="product-flash-sale"
              >
                <span className='text-white font-weight-bold fs-001'>
                    Flash Sale
                </span>
              </div>
            )}
            {data.isCompetitivePricingProduct && (
              <div
                className="product-competitive-price"
              >
                <span className='text-white font-weight-bold fs-001'>
                  Competitive Price
                </span>
              </div>
            )}
          </>
        ),
      },
      {
        key: 'displayName',
        displayText: 'Name',
        renderer: (data) => (
          <span>
            {data.displayName}
          </span>
        ),
      },
      {
        key: 'approxGrammage',
        displayText: 'Approx Grammage',
        renderer: (data) => data?.approxGrammage || '--',
      },
      {
        key: 'mrp',
        displayText: 'Mrp',
      },
      {
        key: 'sellingPrice',
        displayText: 'Selling Price',
      },
      {
        key: 'image',
        displayText: 'Image',
        renderer: (data) => (
          <div
            className="product-image-div"
          >
            <img
              src={data.image}
              alt=""
              className="w-100 h-100"
            />
          </div>
        ),
      },
      {
        key: 'fulfilmentStatus',
        displayText: 'Fulfilment Status',
        renderer: (data) => getStatusIcon(data.fulfilmentStatus),
      },
      // prescri deprecated
      {
        key: 'tag',
        displayText: 'Tag',
        renderer: (data) => Utils.getOrderTagStringFormat(data.tag) || 'NA',
      },
      {
        key: 'quantity',
        displayText: 'QTY',
      },
      {
        key: 'history',
        displayText: 'Product History',
        renderer: (data) => (
          <Button
            variant="outline-primary"
            className="px-2 py-2 fs-01"
            onClick={() => {
              this.setState({
                selectedProductId: data.id,
              });
            }}
          >
            View History
          </Button>
        ),
      },
    ];

    const specialInstructionHeader = [
      {
        key: 'textNote',
        displayText: 'Comment',
        renderer: (data) => data.textNote || 'NA'
      },
      {
        key: 'image',
        displayText: 'Image',
        renderer: (data) => {
          if (data?.images?.length > 0) {
            return (
              <div className='d-flex'>
                {data?.images?.map((img, index) => (
                  <div className="product-image-div mr-2" key={index}>
                    <a href={img} target="_blank" rel="noopener noreferrer">
                      <img
                        src={img}
                        alt=""
                        className="w-100 h-100"
                      />
                    </a>
                  </div>
                ))}
              </div>
            );
          }
          return 'NA';
        },
      },
      
      
      {
        key: 'VoiceData',
        displayText: 'Voice Note',
        renderer: (data) => {
          const audioUrl = data?.VoiceData;
          return (
            data?.VoiceData ? <CustomAudioPlayer audioSrc={audioUrl} /> : 'NA'        
          )
        }
      }
      
    ];

    const customItemsConfig = [
      {
        key: 'id',
        displayText: 'Id',
      },
      {
        key: 'displayName',
        displayText: 'Name',
        renderer: this.productNameRenderer,
      },
      {
        key: 'sellingPrice',
        displayText: 'SP',
      },
      {
        key: 'unitString',
        displayText: 'Unit',
      },
      {
        key: 'image',
        displayText: 'Image',
        renderer: (data) => (
          <div
            className="product-image-div"
          >
            <img
              src={data.image}
              alt=""
              className="w-100 h-100"
            />
          </div>
        ),
      },
      {
        key: 'quantity',
        displayText: 'QTY',
      },
    ];

    if (!orderDetails && loading) {
      return (
        <div
          className="h-100 d-flex align-items-center
               justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!orderDetails && !loading && error) {
      return (
        <div
          className="h-100 d-flex align-items-center
               justify-content-center"
        >
          <ErrorHandler
            retryLogic={() => {
              this.setState({
                loading: true,
                error: false,
              }, () => this.handleLoad());
            }}
          />
        </div>
      );
    }

    const {
      createdOn, pickupFromStore, payment, productCount,
      offersApplicable, coupon, orderItems, deliveryDetails,
      customer, store, customOrderItems,specialInstructions,
    } = orderDetails;


    const couponDetails = [
      {
        header: 'Coupon Applied',
        content: getStatusIcon(coupon.couponAppliedStatus),
      },
    ];
    if (coupon.couponAppliedStatus) {
      couponDetails.push.apply(couponDetails, [
        {
          header: 'Coupon',
          content: coupon.couponApplied.couponCode,
        },
        {
          header: 'Discount Type',
          content: coupon.couponApplied.discountType,
        },
        {
          header: 'Discount Amount',
          content: coupon.discountAmount,
        }]);
    }

    const offerDetails = [
      {
        header: 'Offer Applied',
        content: getStatusIcon(offersApplicable?.length),
      },
    ];
    let spendOffer = null;
    const bogoOffers = [];
    offersApplicable.forEach((offer) => {
      if (offer.offerType === 'SPECIAL_BOGO_OFFER') {
        bogoOffers.push(offer);
      }
      if (offer.offerType === 'SPECIAL_SPEND_OFFER') {
        spendOffer = offer;
      }
    });
    if (spendOffer) {
      offerDetails.push.apply(offerDetails, [{
        header: 'Spend Offer',
        content: getStatusIcon(true),
      }]);
    }
    if (bogoOffers.length) {
      offerDetails.push.apply(offerDetails, [{
        header: 'Bogo Offer',
        content: getStatusIcon(true),
      }]);
    }

    let deliveryDetails3PLSupportJSX = null;
    const keepSearchingJSX = (
      <Col
        xs="auto"
        className=""
      >
        <Button
          variant="primary"
          onClick={() => {
            this.handle3PLDeliverySupportPatch('KEEP_SEARCHING');
          }}
        >
          &nbsp;&nbsp;Keep Searching&nbsp;&nbsp;
        </Button>
      </Col>
    );
    const selfDeliveryJSX = (
      <Col
        xs="auto"
        className=""
      >
        <Button
          variant="outline-primary"
          onClick={() => {
            this.handle3PLDeliverySupportPatch('SELF_DELIVERY');
          }}
        >
          &nbsp;&nbsp;Self Delivery&nbsp;&nbsp;
        </Button>
      </Col>
    );
    const cancelOrderJSX = (
      <Col
        xs="auto"
        className=""
      >
        <Button
          variant="outline-danger"
          onClick={() => {
            this.handle3PLDeliverySupportPatch('CANCELLED');
          }}
        >
          &nbsp;&nbsp;Cancel Order&nbsp;&nbsp;
        </Button>
      </Col>
    );
    if (isDeliverySupport3PLPatch) {
      deliveryDetails3PLSupportJSX = (
        <Col xs={24} className="text-center">
          {getLoadingLayout()}
        </Col>
      );
    } else if (orderDetails && orderDetails.deliveryPartnerDetails) {
      switch (orderDetails.deliveryPartnerDetails.status) {
        case 'IN_PROGRESS':
          deliveryDetails3PLSupportJSX = (
            <>
              <Col xs={24} className="text-center text-warning">
                We will notify Retailer, Once Delivery Partner is available
              </Col>
            </>
          );
          break;

        case 'SUCCESS':
          deliveryDetails3PLSupportJSX = (
            <>
              <Col xs={24} className="px-2 text-center">
                {
                  (orderDetails.deliveryStatus === 'CANCELLED') ? (
                    <span className="text-danger">
                      Order Cancelled.
                    </span>
                  ) : (
                    <span className="">
                      {
                        (orderDetails.deliveryStatus === 'DELIVERED')
                          ? 'Order Delivered' : 'Delivery Partner Assigned Successfully.'
                      }
                    </span>
                  )
                }
              </Col>
              <Col xs={24} className="px-2 pt-2 text-center">
                <span className="text-secondary">
                  {orderDetails.deliveryPartnerDetails.response.orderDeliveryStatus || 'Preparing Order'}
                  &nbsp;&nbsp;|&nbsp;&nbsp; Rider -
                  {' '}
                  {orderDetails.deliveryPartnerDetails.response.riderName || 'Delivery Partner'}
                  &nbsp;&nbsp;|&nbsp;&nbsp;ETA -
                  {' '}
                  {
                    dateString(new Date(
                      orderDetails.deliveryPartnerDetails.response.riderETA.sellerETA,
                    ))
                  }
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <a
                    href={orderDetails.deliveryPartnerDetails.response.trackURL}
                    target="_block"
                  >
                    Track Here
                  </a>
                </span>
              </Col>
            </>
          );
          break;

        case 'FAILED':
          deliveryDetails3PLSupportJSX = (
            <>
              <Col xs={24} className="text-center">
                <span className="text-danger">
                  Unable to assign Delivery Partner
                </span>
              </Col>
              <Col xs={24}>
                <Row className="pt-2 d-flex justify-content-center">
                  {selfDeliveryJSX}
                  {cancelOrderJSX}
                </Row>
              </Col>
            </>
          );
          break;

        case 'RETRY':
          deliveryDetails3PLSupportJSX = (
            <>
              <Col xs={24} className="text-center">
                <span className="text-danger">
                  Could Not find Delivery Partner
                </span>
              </Col>
              <Col xs={24}>
                <Row className="pt-2 d-flex justify-content-center">
                  {keepSearchingJSX}
                  {selfDeliveryJSX}
                </Row>
              </Col>
            </>
          );
          break;

        default:
          deliveryDetails3PLSupportJSX = (
            <Col xs={24} className="text-center">
              <Button
                variant="outline-primary"
                onClick={() => {
                  this.getAssignDeliveryPartnerDetails();
                }}
              >
                &nbsp;&nbsp;Assign Delivery Partner&nbsp;&nbsp;
              </Button>
            </Col>
          );
          break;
      }
    }

    let valueOfferApplied = null;
    let spendOfferApplied = null;
    let bogoOfferApplied = null;

    if (orderDetails) {
      valueOfferApplied = (
        orderDetails.valueOffersApplicable || []
      ).find((offer) => offer.appliedStatus);
    }
    if (orderDetails) {
      spendOfferApplied = (
        orderDetails.offersApplicable || []
      ).find((offer) => (offer.offerType === 'SPECIAL_SPEND_OFFER' && offer.appliedStatus));
    }
    if (orderDetails) {
      bogoOfferApplied = (
        orderDetails.offersApplicable || []
      ).find((offer) => (offer.offerType === 'SPECIAL_BOGO_OFFER' && offer.appliedStatus));
    }

    return (
      <Container
        fluid
        className="mx-0"
        id="order-details"
      >
        {
          whatsappNotifications && (
            <WhatsappNotifications
              show={whatsappNotifications}
              orderId={orderId}
              toggleModal={() => { this.toggleModal('whatsappNotifications'); }}
            />
          )
        }
        {
          showPrescription && (
            <ViewPrescription
              show={showPrescription}
              onHide={() => { this.toggleModal('showPrescription'); }}
              prescriptionList={orderDetails.prescriptions}
            />
          )
        }
        {
          transactionMeta && (
            <TransactionMeta
              show={transactionMeta}
              orderId={orderId}
              toggleModal={() => { this.toggleModal('transactionMeta'); }}
            />
          )
        }
        {
          deliveryStatusModal && (
            <UpdatDeliveryStatus
              show={deliveryStatusModal}
              onCancel={this.toggleDeliveryStatusConfirmationModal}
              onUpdate={this.handleChangeDeliveryStatus}
              orderStatus={newDeliveryStatus}
              orderCancellationReasons={orderCancelReason}
            />
          )
        }
        {
          showCommentsModal && (
            <AddComments
              showCommentsModal={showCommentsModal}
              toggleCommentsModal={this.addComments}
              onSuccess={this.onAddComment}
              storeId={orderDetails.store.code}
              orderId={orderDetails.orderId}
            />
          )
        }
        {
          modifyOrderItemsModal && (
            <ModifyOrder
              orderDetails={this.orderDetailsForModification()}
              show={modifyOrderItemsModal}
              modifyOrder={this.onModifyOrder}
              onCancel={this.toggleModifyOrderItems}
              modifiedOrderPatchStatus={modifiedOrderPatchStatus}
              userPermission={userPermission}
              modifyError={modifyError}
            />
          )
        }
        {
          showExotelCallModal && (
            <ExotelCall
              show={showExotelCallModal}
              initiateCallTo={initiateCallTo}
              toggleExotelCallModal={this.toggleExotelCallModal}
              orderDetails={orderDetails}
            />
          )
        }
        {
          showResolveDisputeModal && (
            <ResolveDispute
              onDisputeResolved={() => { this.handleLoad(); }}
              toggleResolveDispute={this.toggleResolveDispute}
              orderId={orderDetails.orderId}
            />
          )
        }
        {
          showReconcileModal && (
           <ReconcileModal
            toggleReconciledModal={this.toggleReconciledModal}
            toggleDeliveryStatusConfirmationModal={this.toggleDeliveryStatusConfirmationModal}
            orderId={orderDetails.orderId}
           />
          )
        }
        {
          showRerouteOrderModal && (
            <RerouteOrder
              show={showRerouteOrderModal}
              onSuccessfulReroute={this.onSuccessfulReroute}
              onCancel={this.toggleRerouteOrder}
              orderId={orderDetails.orderId}
              orderStoreType={orderDetails.orderStoreType}
            />
          )
        }
        {
          whatsappModalCustomer && (
            <WhatAppTemplate
              show={whatsappModalCustomer}
              onHide={() => this.setState({ whatsappModalCustomer: false })}
              phoneNumber={customerPhoneNumber}
              handleRequestProcessing={() => {}}
              templateType="WHATSAPP_CUSTOMER_ORDER_DETAILS_TEMPLATE"
              orderId={orderId}
              storeName={store.name}
            />
          )
        }
        {
          whatsappModalRetailer && (
            <WhatAppTemplate
              show={whatsappModalRetailer}
              onHide={() => this.setState({ whatsappModalRetailer: false })}
              phoneNumber={storePhoneNumber}
              handleRequestProcessing={() => {}}
              templateType="WHATSAPP_RETAILER_ORDER_DETAILS_TEMPLATE"
              orderId={orderId}
            />
          )
        }
        {
          smsModalCustomer && (
            <SMSTemplate
              show={smsModalCustomer}
              onHide={() => this.setState({ smsModalCustomer: false })}
              phoneNumber={customerPhoneNumber}
              handleRequestProcessing={() => {}}
              templateType="SMS_CUSTOMER_ORDER_DETAILS_TEMPLATE"
              orderId={orderId}
              storeName={store.name}
            />
          )
        }
        {
          smsModalRetailer && (
            <SMSTemplate
              show={smsModalRetailer}
              onHide={() => this.setState({ smsModalRetailer: false })}
              phoneNumber={storePhoneNumber}
              handleRequestProcessing={() => {}}
              templateType="SMS_RETAILER_ORDER_DETAILS_TEMPLATE"
              orderId={orderId}
            />
          )
        }
        {
          selectedProductId && (
            <ViewProductHistory
              onHide={() => this.setState({ selectedProductId: '' })}
              productId={selectedProductId}
              orderId={orderDetails.orderId}
            />
          )
        }
        {
          showRerouteDispositionModal && (
            <RerouteDisposition
              show={showRerouteDispositionModal}
              onHide={() => this.setState({ showRerouteDispositionModal: false })}
              orderId={orderDetails?.orderId}
              storeCode={orderDetails?.store?.code}
              openReroutePage={() => this.toggleRerouteOrder()}
            />
          )
        }
        <Row className="mx-6">
          <Col
            className="bg-white py-2"
          >
            <Row>
              {/* <Col
                xs="auto"
                className="d-flex align-items-center sticky-0 py-2 mb-2"
              >
                <Button
                  variant="outline-primary"
                  className="fs-01"
                  onClick={() => { this.toggleModal('whatsappNotifications'); }}
                >
                  SEE WHATSAPP NOTIFICATIONS
                </Button>
              </Col> */}
              { 
                <Col
                  xs="auto"
                  className="d-flex align-items-center sticky-0 py-2 mb-2 pointer-event-none"
                >
                  <Button
                    variant="outline-primary"
                    className="fs-01"
                  >
                    IVR STATUS: {orderDetails?.ivrStatus || '  --  '}
                  </Button>
                </Col>
              }
              {
                orderDetails.isPrescription && (
                  <Col
                    xs="auto"
                    className="d-flex align-items-center sticky-0 py-2 mb-2"
                  >
                    <Button
                      variant="outline-primary"
                      className="fs-01"
                      onClick={() => { this.toggleModal('showPrescription'); }}
                    >
                      VIEW PRESCRIPTION
                    </Button>
                  </Col>
                )
              }
              <Col
                xs="auto"
                className="d-flex align-items-center sticky-0 py-2 mb-2"
              >
                <CustomDropdown
                  item={{
                    key: 'orderManager',
                    displayText: 'Order Manager',
                    options: [
                      {
                        label: 'Select OM',
                        value: '',
                      },
                      {
                        label: 'OM1',
                        value: 'OM1',
                      },
                      {
                        label: 'OM2',
                        value: 'OM2',
                      },
                      {
                        label: 'OM3',
                        value: 'OM3',
                      },
                      {
                        label: 'OM4',
                        value: 'OM4',
                      },
                      {
                        label: 'OM5',
                        value: 'OM5',
                      },
                      {
                        label: 'OM6',
                        value: 'OM6',
                      },
                      {
                        label: 'OM7',
                        value: 'OM7',
                      },
                      {
                        label: 'OM8',
                        value: 'OM8',
                      },
                      {
                        label: 'OM9',
                        value: 'OM9',
                      },
                      {
                        label: 'OM10',
                        value: 'OM10',
                      },
                    ],
                  }}
                  onChange={this.patchOM}
                  selectedVal={orderDetails.owner}
                  disabled={updatingOrderStatus === 'loading'}
                />
              </Col>
              {
                orderDetails.taggedForRedirection && (
                  <Col
                    xs="auto"
                    className="d-flex align-items-center py-2 my-2 fs-01 bg-primary text-white"
                  >
                    TAGGED FOR REDIRECTION
                  </Col>
                )
              }
              <Col
                xs="auto"
                className="d-flex align-items-center py-2 mb-2 ml-auto"
              >
                {
                  orderDetails.isLLSubscribedUser && (
                    <div className='bg-warning d-flex py-2 mb-2 mr-2 border-radius-16 align-items-center'>
                      <img
                        src="/images/subscription-logo.svg"
                        width={117}
                        height={17}
                        alt=""
                      />
                    </div>
                  )
                }
                <div className={`${orderAcceptanceTimeOver ? 'bg-danger' : 'bg-primary'} text-white font-weight-bold px-2 border-radius-4`}>
                  {secondToTimeFormat(totalTimeEllapsed)}
                </div>
              </Col>
            </Row>
          </Col>

          <Col
            xs={24}
            className="bg-white mb-3 pb-4"
          >
            <Col xs={24} className="d-flex px-0 pb-3">
              <Col
                xs={24}
                md={12}
                className="pl-0"
              >
                {getTableLayout([
                  {
                    header: 'Order Id',
                    content: orderDetails.orderId || 'NA',
                  },
                  {
                    header: 'Order Created',
                    content: dateString(createdOn) || 'NA',
                  },
                  {
                    header: 'Order Modified',
                    content: dateString(orderDetails.modifiedOn) || 'NA',
                  },
                  {
                    header: 'Order Description',
                    content: orderDetails.description || 'NA',
                  },
                  {
                    header: 'Redirected To',
                    content: (
                      <div className="d-flex align-items-center">
                        <Link
                          to={`/order-details/${orderDetails.reroutedTo}`}
                          target="_blank"
                          className={`${!orderDetails.reroutedTo ? 'pointer-event-none text-medium' : ''}`}
                        >
                          {orderDetails.reroutedTo || 'NA'}
                        </Link>
                        {
                          orderDetails.reroutedTo && (
                            <div
                              className={`ml-2 ${this.getReroutedOrderStatusColor(orderDetails.reroutedToDeliveryStatus)}`}
                              style={{
                                height: '15px',
                                width: '35px',
                              }}
                            />
                          )
                        }
                      </div>
                    ),
                  },
                  ...orderDetails.grabbedStatus !== '' ? [
                    {
                      header: 'Grab Order Status',
                      content: orderDetails.grabbedStatus,
                    },
                  ] : [],
                  {
                    header: 'Hex ID',
                    content: orderDetails.LLHexId || 'NA',
                  },
                ])}
              </Col>
              <Col
                xs={24}
                md={12}
                className="pr-0"
              >
                {getTableLayout([
                  {
                    header: 'Delivery Status',
                    content: orderDetails.deliveryStatus || 'NA',
                  },
                  {
                    header: 'Fulfilment Status',
                    content: orderDetails.fulfilmentStatus || 'NA',
                  },
                  {
                    header: 'Total Items',
                    content: orderDetails.productCount || 'NA',
                  },
                  {
                    header: 'Delivery Time',
                    content: orderDetails.store.expectedDeliveryTime || 'NA',
                  },
                  {
                    header: 'Reference Order ID',
                    content: (
                      <Link
                        to={`/order-details/${orderDetails.reroutedFrom}`}
                        target="_blank"
                        className={`${!orderDetails.reroutedFrom ? 'pointer-event-none text-medium' : ''}`}
                      >
                        {orderDetails.reroutedFrom || 'NA'}
                      </Link>
                    ),
                  },
                  ...orderDetails.grabbedStatus !== '' ? [
                    {
                      header: 'Order Rejected by Retailer	',
                      content: getStatusIcon(orderDetails.isRejectedByRetailer),
                    },
                  ] : [],
                ])}
              </Col>
            </Col>
            {
              (orderDetails?.deliveryStatus === 'MODIFIED' && orderDetails?.enableAcceptRejectAction) && (
                <Col xs={24} className="pb-4 d-flex flex-row-reverse">
                  <Button
                    variant="link"
                    onClick={() => this.patchModifyChanges('REJECT')}
                    className="mx-2 bg-secondary text-black"
                  >
                    Customer Rejected Changes
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => this.patchModifyChanges('ACCEPT')}
                    className="mx-2"
                  >
                    Customer Accepted Changes
                  </Button>
                </Col>
              )
            }
            <Col xs={24} className="d-flex flex-column justify-content-center">
              {/* {
                (orderDetails.deliveryStatus !== 'DELIVERED'
                  || orderDetails.payment.status !== 'PAID')
                  && !orderDetails.coupon.couponAppliedStatus
                  && (
                    <>
                      <Col
                        xs={24}
                        className="d-flex align-items-center justify-content-center"
                      >
                        Apply Coupon (If Applicable) :
                        &nbsp;&nbsp;
                        <input
                          value={couponCode}
                          name="couponCode"
                          onChange={this.handleChange}
                        />
                        <Button
                          variant="primary"
                          onClick={this.applyCoupon}
                          className="font-weight-bold"
                          disabled={applyingCoupon === 'applying'}
                        >
                          {
                            applyingCoupon === 'applying' ? (
                              <Spinner
                                size="sm"
                                variant="light"
                                animation="border"
                              />
                            ) : (<>➜</>)
                          }
                        </Button>
                      </Col>
                      {
                        applyCouponMsg && (
                          <Col
                            xs={24}
                            className={`py-2 text-center ${applyingCoupon === 'error' ? 'text-danger' : 'text-success'}`}
                          >
                            {applyCouponMsg}
                          </Col>
                        )
                      }
                    </>
                  )
              } */}
              <Col
                xs={24}
                className="d-flex align-items-center justify-content-center mt-4"
              >
                {/* Cloning coupon
                {
                  orderDetails.deliveryStatus === 'CANCELLED'
                  && orderDetails.coupon
                  && orderDetails.coupon.couponApplied
                  && orderDetails.coupon.couponAppliedStatus
                  && (
                    <Button
                      variant="primary"
                      className="mx-2"
                      onClick={this.handleCloneCoupon}
                      disabled={updatingOrderStatus === 'loading'}
                    >
                      Clone Coupon
                    </Button>
                  )
                } */}
                {
                  (orderDetails.deliveryStatus === 'DELIVERED')
                  && !orderDetails.isDisputed && (
                    <Button
                      variant="danger"
                      onClick={() => orderDetails.isReconciled ? this.toggleReconciledModal():
                        this.toggleDeliveryStatusConfirmationModal('CANCELLED')}
                      className="mx-2"
                      disabled={updatingOrderStatus === 'loading'}
                    >
                      Cancel Order
                    </Button>
                  )

                }
                {
                  (orderDetails.deliveryStatus === 'DELIVERED'
                    || orderDetails.deliveryStatus === 'CANCELLED')
                  && !orderDetails.isDisputed && (
                    <Button
                      variant="primary"
                      onClick={() => this.toggleDeliveryStatusConfirmationModal('PENDING')}
                      className="mx-2"
                      disabled={updatingOrderStatus === 'loading'}
                    >
                      Pending Order
                    </Button>
                  )
                }
                {
                  orderDetails.isDisputed && (
                    <Button
                      variant="primary"
                      onClick={this.toggleResolveDispute}
                      className="mx-2"
                      disabled={updatingOrderStatus === 'loading'}
                    >
                      Resolve Dispute
                    </Button>
                  )
                }
                {
                  updatingOrderStatus === 'loading' && (
                    <Spinner size="sm" animation="border" />
                  )
                }
                {
                  updateOrderErrorMsg && (
                    <Col xs="auto" className={`${updatingOrderStatus === 'error' ? 'text-danger' : ''}`}>
                      {updateOrderErrorMsg}
                    </Col>
                  )
                }
                {
                  updatingOrderStatus === 'error' && (
                    <Button
                      variant="primary"
                      onClick={this.retry}
                    >
                      Retry
                    </Button>
                  )
                }
              </Col>
            </Col>

            {/* Reroute Order */}
            {
              orderDetails.deliveryStatus !== 'DELIVERED'
              && (
                <>
                  <Col xs={24} className="mt-2 d-flex justify-content-center">
                    <Col
                      xs="auto"
                    >
                      <Button
                        variant="danger"
                        className="font-weight-bold"
                        onClick={() => this.setState({ showRerouteDispositionModal: true })}
                        disabled={!!orderDetails.isReroutedOrder}
                      >
                        Reroute Order
                      </Button>
                    </Col>

                    {
                      !orderDetails.inGrabOrderFlow && (
                        <>
                          {/* Order Work In Progress */}
                          {
                            !orderDetails.workInProgress && (
                              <Col
                                xs="auto"
                              >
                                <Button
                                  variant="outline-primary"
                                  className="font-weight-bold"
                                  onClick={() => this.markForGrab(false)}
                                >
                                  Order WIP
                                </Button>
                              </Col>
                            )
                          }

                          {/* Send for grab */}
                          {
                            orderDetails.workInProgress && (
                              <Col
                                xs="auto"
                              >
                                <Button
                                  variant="outline-primary"
                                  className="font-weight-bold"
                                  onClick={() => this.markForGrab(true)}
                                >
                                  Send for GRAB
                                </Button>
                              </Col>
                            )
                          }
                      </>
                      )
                    }
                  </Col>
                  {
                    grabOrderMsg && (
                      <Col xs={24} className="mt-2">
                        <div className="text-danger text-center">
                          {grabOrderMsg}
                        </div>
                      </Col>
                    )
                  }
                </>
              )
            }
          </Col>

          {
           specialInstructions && (
              <Col
              xs={24}
              className={`bg-white mb-3 ${customOrderItems.length > 0 ? '' : 'mb-3'}`}
            >
              <Row
                className="mx-0 rounded-0"
              >
                <Col
                  xs={24}
                  className="headers"
                >
                  <div>
                    <span className="font-weight-bold">Special Instructions</span>
                  </div>
                </Col>
                <Col
                  xs={24}
                  className="px-0"
                >
                  <CustomTable
                    keyField="id"
                    headers={specialInstructionHeader}
                    content={[specialInstructions]}
                    isPaginated={false}
                    totalItems={[specialInstructions]?.length}
                  />
                </Col>
                
              </Row>
            </Col>
            )
          }

            <Col
            xs={24}
            className={`bg-white mb-3 ${customOrderItems.length > 0 ? '' : 'mb-3'}`}
          >
            <Row
              className="mx-0 rounded-0"
            >
              <Col
                xs={24}
                className="headers"
              >
                <div>
                  <span className="font-weight-bold">Order Items</span>
                  {` - ${productCount} Items`}
                </div>
                {
                  (orderDetails.deliveryStatus === 'PENDING'
                  || orderDetails.deliveryStatus === 'MODIFIED'
                  || orderDetails.deliveryStatus === 'READY_TO_SHIP'
                  ) && (
                    <div>
                      <Button
                        variant="primary"
                        onClick={this.toggleModifyOrderItems}
                        className="button-in-header"
                      >
                        MODIFY
                      </Button>
                    </div>
                  )
                }
              </Col>
              <Col
                xs={24}
                className="px-0"
              >
                <CustomTable
                  keyField="id"
                  headers={headers}
                  content={orderItems}
                  isPaginated={false}
                  totalItems={orderItems.length}
                />
              </Col>
            </Row>
          </Col>

          {
            customOrderItems.length > 0 && (
              <Col
                xs={24}
                className="bg-white mb-3"
              >
                <Row
                  className="mx-0 rounded-0"
                >
                  <Col
                    xs={24}
                    className="headers custom-product-tab"
                  >
                    <div>
                      <span className="font-weight-bold">Custom Order Items</span>
                    </div>
                    {
                      (orderDetails.deliveryStatus === 'PENDING'
                      || orderDetails.deliveryStatus === 'MODIFIED') && (
                        <div>
                          <Button
                            variant="primary"
                            onClick={this.toggleModifyOrderItems}
                            className="button-in-header"
                          >
                            MODIFY
                          </Button>
                        </div>
                      )
                    }
                  </Col>
                  <Col
                    xs={24}
                    className="px-0"
                  >
                    <CustomTable
                      keyField="id"
                      headers={customItemsConfig}
                      content={customOrderItems}
                      isPaginated={false}
                      totalItems={customOrderItems.length}
                    />
                  </Col>
                </Row>
              </Col>
            )
          }

          {/* Order Comments */}
          <Col
            xs={24}
            className="mb-3 bg-white"
          >
            <Col xs={24} className="headers">
              <b>Order Comments</b>
              <Button
                variant="link"
                className="py-0"
                onClick={() => this.addComments('order')}
              >
                <Svg
                  svg="editIcon"
                  height={24}
                  width={24}
                  stroke="#ffffff"
                />
              </Button>
            </Col>
            <Col xs={24} className="pt-2 px-0 justify-content-center d-flex">
              {
                orderCommentsLoadReq === 'loading' && (
                  getLoadingLayout()
                )
              }
              {
                orderCommentsLoadReq === 'error' && (
                  getErrorLayout(() => { this.getOrderComments(); })
                )
              }
              {
                orderCommentsLoadReq === 'success' && (
                  <CustomTable
                    headers={[
                      {
                        key: 'comment',
                        displayText: 'Comment',
                      },
                      {
                        key: 'date',
                        displayText: 'Date',
                        renderer: (rowData) => dateString(rowData.date),
                      },
                      {
                        key: 'user',
                        displayText: 'User',
                      },
                    ]}
                    keyField="date"
                    content={orderComments}
                    isPaginated={false}
                    totalItems={orderComments?.length}
                  />
                )
              }
            </Col>
          </Col>

          {/* Store Comments */}
          <Col
            xs={24}
            className="mb-3 bg-white"
          >
            <Col xs={24} className="headers">
              <b>Store Comments</b>
              <Button
                variant="link"
                className="py-0"
                onClick={() => this.addComments('store')}
              >
                <Svg
                  svg="editIcon"
                  height={24}
                  width={24}
                  stroke="#ffffff"
                />
              </Button>
            </Col>
            <Col xs={24} className="pt-2 px-0 justify-content-center d-flex">
              {
                storeCommentsLoadReq === 'loading' && (
                  getLoadingLayout()
                )
              }
              {
                storeCommentsLoadReq === 'error' && (
                  getErrorLayout(() => { this.getStoreComments(); })
                )
              }
              {
                storeCommentsLoadReq === 'success' && (
                  <CustomTable
                    headers={[
                      {
                        key: 'comment',
                        displayText: 'Comment',
                      },
                      {
                        key: 'date',
                        displayText: 'Date',
                        renderer: (rowData) => dateString(rowData.date),
                      },
                      {
                        key: 'user',
                        displayText: 'User',
                      },
                    ]}
                    keyField="date"
                    content={storeComments}
                    isPaginated={false}
                    totalItems={storeComments?.length}
                  />
                )
              }
            </Col>
          </Col>

          {/* Payment Details */}
          <Col
            xs={24}
            className="mb-3 pb-4 bg-white"
          >
            <Col xs={24} className="headers">
              <b>Payment Details</b>
              <Button
                variant="primary"
                className="button-in-header"
                onClick={() => { this.toggleModal('transactionMeta'); }}
              >
                Transaction Meta
              </Button>
            </Col>
            <Col xs={24} className="d-flex px-0 pb-3">
              <Col
                xs={24}
                md={12}
                className="pl-0"
              >
                {getTableLayout([
                  {
                    header: 'Payment Mode',
                    content: payment.mode || 'NA',
                  },
                  {
                    header: 'Payment Id',
                    content: payment.id || 'NA',
                  },
                  {
                    header: 'Payment Created',
                    content: dateString(payment.created_on) || 'NA',
                  },
                  {
                    header: 'Payment Modified',
                    content: dateString(payment.modified_on) || 'NA',
                  },
                  {
                    header: 'Status',
                    content: payment.status || 'NA',
                  },
                  {
                    header: 'Payment Method',
                    content: payment.transactionChannel || 'NA',
                  },
                  {
                    header: 'Paid Amount',
                    content: payment.paidAmount || 'NA',
                  },
                  {
                    header: 'Settlement Status',
                    content: payment.settlementStatus || 'NA',
                  },
                  {
                    header: 'Refund Status',
                    content: payment.refundStatus || 'NA',
                  },
                  {
                    header: 'Total Refunded Amount',
                    content: payment.totalRefundedAmount || 'NA',
                  },
                ])}
              </Col>
              <Col
                xs={24}
                md={12}
                className="pr-0"
              >
                {getTableLayout([
                  {
                    header: 'Order Amount',
                    content: `${payment.freeHomeDeliveryApplied
                      ? `${payment.billAmount || 'NA'}`
                      : `${(payment.billAmount - payment.deliveryAmountMeta.amountCharge - payment.deliveryAmountMeta.distanceCharge) || 'NA'}`
                    }`,
                  },
                  {
                    header: 'Platform Fee (incl. GST)',
                    content: `+${payment.platformFee.finalValueAndGst || 0}`,
                  },
                  {
                    header: 'Delivery Charge',
                    content: `+${payment.deliveryAmountMeta.amountCharge || 0}`,
                  },
                  {
                    header: 'Distance Charge',
                    content: `+${payment.deliveryAmountMeta.distanceCharge || 0}`,
                  },
                  {
                    header: 'Coupon Discount',
                    content: `-${payment.couponDiscount || 0}`,
                  },
                  {
                    header: 'Value Offer Discount',
                    content: `-${payment.valueDiscount || 0}`,
                  },
                  {
                    header: 'Payable Amount',
                    content: payment.payableAmount || 0,
                  },
                ])}
              </Col>
            </Col>
          </Col>

          {/* Cloned Coupons */}
          {
            orderDetails.deliveryStatus !== 'CANCELLED'
            && orderDetails.clonedCoupons.length > 0
            && (
              <Col
                xs={24}
                className="mb-3 pb-4 bg-white"
              >
                <Col xs={24} className="headers"><b>Cloned Coupons</b></Col>
                <Col xs={24} className="px-0">
                  <CustomTable
                    headers={[
                      {
                        key: 'couponCode',
                        displayText: 'Coupon Code',
                      },
                      {
                        key: 'createdOn',
                        displayText: 'Created On',
                        renderer: (data) => dateString(data.createdOn),
                      },
                      {
                        key: 'validFrom',
                        displayText: 'Valid From',
                        renderer: (data) => dateString(data.validFrom),
                      },
                      {
                        key: 'validTill',
                        displayText: 'Valid Till',
                        renderer: (data) => dateString(data.validTill),
                      },
                    ]}
                    keyField="couponCode"
                    content={orderDetails.clonedCoupons}
                    isPaginated={false}
                    totalItems={orderDetails.clonedCoupons.length}
                  />
                </Col>
              </Col>
            )
          }

          {/* Value Offer */}
          {
            valueOfferApplied
            && (
              <Col
                xs={24}
                className="mb-3 pb-4 bg-white"
              >
                <Row className="headers mx-0"><Col><b>Value Offer</b></Col></Row>
                <ValueOffer
                  valueOfferApplied={valueOfferApplied}
                  isValueOffer
                />
              </Col>
            )
          }

          {/* Spend Offer */}
          {
            spendOfferApplied && (
              <Col
                xs={24}
                className="mb-3 pb-4 bg-white"
              >
                <Row className="headers mx-0"><Col><b>Spend Offer</b></Col></Row>
                <ValueOffer
                  valueOfferApplied={spendOfferApplied}
                />
              </Col>
            )
          }

          {/* BOGO Offer */}
          {
            bogoOfferApplied && (
              <Col
                xs={24}
                className="mb-3 pb-4 bg-white"
              >
                <Row className="headers mx-0"><Col><b>BOGO Offer</b></Col></Row>
                <ValueOffer
                  valueOfferApplied={bogoOfferApplied}
                />
              </Col>
            )
          }

          {
            orderDetails.coupon
            && orderDetails.coupon.couponApplied
            && orderDetails.coupon.couponAppliedStatus
            && (
              <Col
                xs={24}
                className="mb-3 pb-4 bg-white"
              >
                <Col xs={24} className="headers"><b>Coupon Applied</b></Col>
                <Col xs={24} className="d-flex px-0 pb-3">
                  <Col
                    xs={24}
                    md={12}
                    className="pl-0"
                  >
                    {getTableLayout([
                      {
                        header: 'Coupon Id',
                        content: orderDetails.coupon.couponApplied.couponId || 'NA',
                      },
                      {
                        header: 'Coupon Code',
                        content: orderDetails.coupon.couponApplied.couponCode,
                      },
                      {
                        header: 'Discount Applied',
                        content: orderDetails.coupon.discountAmount,
                      },
                      {
                        header: 'Funded By',
                        content: orderDetails.coupon.couponApplied.fundedBy || 'NA',
                      },
                    ])}
                  </Col>
                  <Col
                    xs={24}
                    md={12}
                    className="pr-0"
                  >
                    {getTableLayout([
                      {
                        header: 'Min Spend Amount',
                        content: orderDetails.coupon.couponApplied.minSpendAmount || 'NA',
                      },
                      {
                        header: 'Max Discount',
                        content: orderDetails.coupon.couponApplied.maxDiscount || 'NA',
                      },
                      {
                        header: 'Payment Modes',
                        content: orderDetails.coupon?.couponApplied?.paymentMode?.map((mode) => (
                            mode.isEnabled
                              ? `${mode.code}, `
                              : ''
                          )),
                      },
                      {
                        header: 'Valid Till',
                        content: dateString(orderDetails.coupon.couponApplied.validTill) || 'NA',
                      },
                    ])}
                  </Col>
                </Col>
              </Col>
            )
          }

          {/* 3PL Delivery Support */}
          {
            orderDetails.isDeliveredByPartner && (
              <Col
                xs={24}
                className="mb-3 pb-4 bg-white"
              >
                <Row className="headers mx-0"><Col><b>3PL Delivery Status</b></Col></Row>
                {
                  orderDetails.deliveryStatus === 'PENDING' ? (
                    <Row className="mx-0 pt-2 justify-content-center text-secondary fs-2">
                      <i>No 3PL Info Available</i>
                    </Row>
                  ) : (
                    <>
                      {deliveryDetails3PLSupportJSX}
                      {
                        isDeliverySupport3PLPatchFailed && (
                          <Row className="text-danger fs-1">
                            Something Broke, Please try Again!!
                          </Row>
                        )
                      }
                    </>
                  )
                }
              </Col>
            )
          }

          {/* Delivery Details */}
          <Col
            xs={24}
            className="mb-3 pb-4 bg-white"
          >
            <Col xs={24} className="headers"><b>Delivery Address</b></Col>
            {
              !pickupFromStore ? (
                <Col xs={24} className="px-0">
                  {getTableLayout([
                    {
                      header: 'Google Address',
                      content: (deliveryDetails?.googleAddress) || 'NA',
                    },
                    {
                      header: 'Flat Number',
                      content: (deliveryDetails?.flatNumber) || 'NA',
                    },
                    {
                      header: 'Building Name',
                      content: (deliveryDetails?.buildingName) || 'NA',
                    },
                    {
                      header: 'Street Name',
                      content: (deliveryDetails?.streetName) || 'NA',
                    },
                    {
                      header: 'Locality',
                      content: (deliveryDetails?.localityName) || 'NA',
                    },
                    {
                      header: 'Landmark',
                      content: (deliveryDetails?.landmark) || 'NA',
                    },
                  ])}
                </Col>
              ) : (
                <Col xs={24} className="pt-2 fs-4 text-center px-0 text-secondary">
                  <i>Pick up from store</i>
                </Col>
              )
            }
          </Col>

          {/* Customer Details */}
          <Col
            xs={24}
            md={12}
            className="mb-3 pb-4 bg-white"
          >
            <Col xs={24} className="headers"><b>Customer Detail</b></Col>
            <Col xs={24} className="px-0">
              {getTableLayout([
                {
                  header: 'Id',
                  content: customer.id || 'NA',
                },
                {
                  header: 'Name',
                  content: customer.name || 'NA',
                },
                {
                  header: 'Address',
                  content: deliveryDetails?.googleAddress || 'NA',
                },
                {
                  header: 'Phone Number',
                  content: (
                    <Button
                      variant="link"
                      onClick={() => this.toggleExotelCallModal('customer')}
                      className="py-0"
                    >
                      <span className="pr-2">
                        <Svg
                          svg="call"
                          width="24"
                          height="24"
                        />
                      </span>
                      { customer.phoneNumber }
                    </Button>
                  ),
                },
                {
                  header: 'Message',
                  content: (
                    <>
                      <Button
                        variant="link"
                        onClick={() => {
                          this.setState({
                            whatsappModalCustomer: true,
                            customerPhoneNumber: customer.phoneNumber,
                          });
                        }}
                      >
                        <Svg
                          svg="whatsapp"
                          width="24"
                          height="24"
                        />
                      </Button>
                      <Button
                        variant="link"
                        onClick={() => {
                          this.setState({
                            smsModalCustomer: true,
                            customerPhoneNumber: customer.phoneNumber,
                          });
                        }}
                      >
                        <Svg
                          svg="sms"
                          width="24"
                          height="24"
                        />
                      </Button>
                    </>
                  ),
                },
              ])}
            </Col>
          </Col>

          {/* Store Details */}
          <Col
            xs={24}
            md={12}
            className="mb-3 pb-4 bg-white"
          >
            <Col xs={24} className="headers"><b>Store Detail</b></Col>
            <Col xs={24} className="px-0">
              {getTableLayout([
                {
                  header: 'Code',
                  content: store.code,
                },
                {
                  header: 'Name',
                  content: store.name,
                },
                {
                  header: 'Phone Number',
                  content: (
                    <Button
                      variant="link"
                      onClick={() => this.toggleExotelCallModal('retailer')}
                      className="py-0"
                    >
                      <span className="pr-2">
                        <Svg
                          svg="call"
                          width="24"
                          height="24"
                        />
                      </span>
                      { store.phoneNumber }
                    </Button>
                  ),
                },
                {
                  header: 'Message',
                  content: (
                    <>
                      <Button
                        variant="link"
                        onClick={() => {
                          this.setState({
                            whatsappModalRetailer: true,
                            storePhoneNumber: store.phoneNumber,
                          });
                        }}
                      >
                        <Svg
                          svg="whatsapp"
                          width="24"
                          height="24"
                        />
                      </Button>
                      <Button
                        variant="link"
                        onClick={() => {
                          this.setState({
                            smsModalRetailer: true,
                            storePhoneNumber: store.phoneNumber,
                          });
                        }}
                      >
                        <Svg
                          svg="sms"
                          width="24"
                          height="24"
                        />
                      </Button>
                    </>
                  ),
                },
              ])}
            </Col>
          </Col>
          
          {/* Order History */}
          <Col
            xs={24}
            className="mb-3 bg-white"
          >
            <Col xs={24} className="headers"><b>Order History</b></Col>
            <Col xs={24} className="px-0">
              <CustomTable
                headers={[
                  {
                    key: 'status',
                    displayText: 'Status',
                  },
                  {
                    key: 'paymentStatus',
                    displayText: 'Payment Status',
                  },
                  {
                    key: 'source',
                    displayText: 'Source' || '--',
                  },
                  {
                    key: 'modifiedOn',
                    displayText: 'Modified On',
                    renderer: (data) => dateString(data.modifiedOn),
                  },
                ]}
                keyField="modifiedOn"
                content={orderDetails.statusHistory}
                isPaginated={false}
                totalItems={orderDetails.statusHistory.length}
              />
            </Col>
          </Col>

          {/* Dispute Tracking */}
          <Col
            xs={24}
            className="mb-3 bg-white"
          >
            <Col xs={24} className="headers"><b>Dispute Tracking</b></Col>
            <Col xs={24} className="px-0">
              <CustomTable
                headers={[
                  {
                    key: 'createdOn',
                    displayText: 'Created On',
                    renderer: (data) => dateString(data.createdOn),
                  },
                  {
                    key: 'comment',
                    displayText: 'Comment',
                  },
                  {
                    key: 'newDeliveryStatus',
                    displayText: 'New Delivery Status',
                  },
                  {
                    key: 'previousDeliveryStatus',
                    displayText: 'Prev Delivery Status',
                  },
                  {
                    key: 'agent',
                    displayText: 'Agent',
                  },
                ]}
                keyField="createdOn"
                content={disputeLogs}
                isPaginated={false}
                totalItems={disputeLogs?.length}
              />
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

OrderDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func.isRequired,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default OrderDetails;
